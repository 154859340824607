import { useCreateContext } from './create-context';

export const EVENTS_SELECT = 'EVENTS_SELECT';

const initialState = {
  eventId: '',
};

type State = typeof initialState;
type Action = any;

function reducer(state: State, action: Action) {
  switch (action.type) {
    case EVENTS_SELECT:
      return {
        ...state,
        eventId: action.payload,
      };
    default:
      return state;
  }
}
const [useEventsState, useEventsDispatch, EventsProvider] = useCreateContext(
  initialState,
  reducer
);

export { useEventsState, useEventsDispatch, EventsProvider };
