import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import smoothscroll from 'smoothscroll-polyfill';
import { onError } from '@apollo/client/link/error';
import { ApolloProvider } from '@apollo/react-hooks';
import 'antd/dist/antd.css';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { ApolloLink, split } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { BaseProvider } from 'baseui';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { Client as StyleTron } from 'styletron-engine-atomic';
import { Provider as StyleTronProvider } from 'styletron-react';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { EventsProvider } from './context/events-context';
import store from './redux/index';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';
import { theme } from './theme';
import './theme/global.css';

//Deploy 2021-11-04 by Xuan Nghiem Nguyen
function App() {
  smoothscroll.polyfill();
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype['msMatchesSelector'];
  }
  const engine = new StyleTron();
  const errorLink: any = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors)
      graphQLErrors.map(({ message, locations, path }) => {
        if (message.includes('Unauthorized')) {
          localStorage.clear();
          return window.location.replace('/login');
        }
        return console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
        );
      });
    if (networkError) console.log(`[Network error]: ${networkError}`);
  });
  const graphqlUrl =
    process.env.REACT_APP_API_URL ||
    `${process.env.REACT_APP_SSL === 'true' ? 'https' : 'http'}://${
      window.location.host
    }/graphql`;
  const socketEndpoint =
    process.env.REACT_APP_SOCKET_URL ||
    `${process.env.REACT_APP_SSL === 'true' ? 'wss' : 'ws'}://${
      window.location.host
    }/graphql`;

  const httpLink = new HttpLink({
    uri: graphqlUrl,
  });
  const wsClient = new SubscriptionClient(socketEndpoint, {
    reconnect: true,
    connectionParams: {
      authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
    },
  });
  const wsLink = new WebSocketLink(wsClient);
  const authLink = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${window.localStorage.getItem('access_token')}`,
      },
    }));
    return forward(operation);
  });
  const link = split(
    ({ query }) => {
      const operation = getMainDefinition(query)['operation'];
      const kind = getMainDefinition(query)['kind'];
      return kind === 'OperationDefinition' && operation === 'subscription';
    },
    wsLink,
    httpLink,
  );
  const client = new ApolloClient({
    link: authLink.concat(errorLink).concat(link),
    cache: new InMemoryCache({
      addTypename: false,
    }),
  });
  return (
    <ApolloProvider client={client as any}>
      <StyleTronProvider value={engine}>
        <BaseProvider theme={theme}>
          <EventsProvider>
            <Provider store={store}>
              <BrowserRouter>
                <Routes />
              </BrowserRouter>
            </Provider>
          </EventsProvider>
        </BaseProvider>
      </StyleTronProvider>
    </ApolloProvider>
  );
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
