/* eslint-disable */
import React, { useContext, lazy, Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import {
  LOGIN,
  PRODUCTS,
  CATEGORY,
  EVENT,
  VOTING_SCREEN,
  CUSTOMERS,
  MAKER,
  STAFF_MEMBERS,
  EVENT_CONTROLLER,
  EVENT_CONTROLLER_REFACTOR,
  STATISTICS,
  STATISTICS_ONGOING,
  EXPORT_PREVIEW,
  EXPORT_PREVIEW_MAKER,
} from './settings/constants';
import AuthProvider, { AuthContext } from './context/auth';
import { InLineLoader } from './components/InlineLoader/InlineLoader';
const Products = lazy(() => import('./containers/Products/Products'));
const AdminLayout = lazy(() => import('./containers/Layout/Layout'));
const Statistics = lazy(() => import('./containers/Statistics/Statistics'));
const StatisticsOngoing = lazy(
  () => import('./containers/StatisticsOngoing/StatisticsOngoing'),
);
const Category = lazy(() => import('./containers/Category/Category'));
const Events = lazy(() => import('./containers/Events/Events'));
const VotingScreen = lazy(() => import('./containers/VotingScreen'));
const EventController = lazy(() => import('./containers/EventController'));
const EventControllerRefactor = lazy(
  () => import('./containers/EventControllerRefactor'),
);
const StaffMembers = lazy(
  () => import('./containers/StaffMembers/StaffMembers'),
);
const Customers = lazy(() => import('./containers/Customers/Customers'));
const Makers = lazy(() => import('./containers/Makers/Makers'));
const Login = lazy(() => import('./containers/Login/Login'));
const NotFound = lazy(() => import('./containers/NotFound/NotFound'));
const ExportQRCode = lazy(() => import('./containers/ExportQRCode'));
const ExportMakerQRCode = lazy(() => import('./containers/ExportQRCode/makerExport'));
/**
 *
 *  A wrapper for <Route> that redirects to the login
 * screen if you're not yet authenticated.
 *
 */

function PrivateRoute({ children, ...rest }) {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const Routes = () => {
  return (
    <AuthProvider>
      <Suspense fallback={<InLineLoader />}>
        <Switch>
          <PrivateRoute exact={true} path={STATISTICS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                {/* <Statistics /> */}
                <StatisticsOngoing />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute exact={true} path={STATISTICS_ONGOING}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StatisticsOngoing />
                {/* <Dashboard /> */}
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={PRODUCTS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Products />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CATEGORY}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Category />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={EVENT}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Events />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={CUSTOMERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Customers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={MAKER}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <Makers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={VOTING_SCREEN}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <VotingScreen />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={STAFF_MEMBERS}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <StaffMembers />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={`${EVENT_CONTROLLER_REFACTOR}/:id`} exact={false}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                {/* <EventController /> */}
                <EventControllerRefactor />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <PrivateRoute path={`${EVENT_CONTROLLER_REFACTOR}`} exact={false}>
            <AdminLayout>
              <Suspense fallback={<InLineLoader />}>
                <EventControllerRefactor />
              </Suspense>
            </AdminLayout>
          </PrivateRoute>
          <Route path={`${EXPORT_PREVIEW}/:id`} exact={true}>
            <Suspense fallback={<InLineLoader />}>
              <ExportQRCode />
            </Suspense>
          </Route>
          <Route path={`${EXPORT_PREVIEW_MAKER}/:id`} exact={true}>
            <Suspense fallback={<InLineLoader />}>
              <ExportMakerQRCode />
            </Suspense>
          </Route>
          <Route path={LOGIN}>
            <Login />
          </Route>
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </AuthProvider>
  );
};

export default Routes;
