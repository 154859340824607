import gql from 'graphql-tag';

const MUTATION_LOGIN = gql`
  mutation login($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      username
      access_token
      fullName
      id
    }
  }
`;

export { MUTATION_LOGIN };
