let initialState = {
    eventChosen: '',
    dataProduct: [],
    categoryCode: '',
    makersChosen: [],
    makerOfEvent: []
};

const reducer = (state = initialState, action) => {
    if (action.type === 'SET_EVENT_CHOSEN') {
        state = { ...state, eventChosen: action.payload };
        return state;
    }
    if (action.type === 'SET_PRODUCT_DATA') {
        state = { ...state, dataProduct: action.payload };
        return state;
    }
    if (action.type === 'SET_CATEGORY_CODE') {
        state = { ...state, categoryCode: action.payload };
        return state;
    }
    if (action.type === 'SET_MAKER_CHOSEN') {
        state = { ...state, makersChosen: action.payload };
        return state;
    }
    if (action.type === 'SET_MAKER_OF_EVENT') {
        state = { ...state, makerOfEvent: action.payload };
        return state;
    }
    return state;
};

export default reducer;
