import { combineReducers } from 'redux';
import currentUser from './currentUser';
import event from './event';
import loading from './loading';

const myReducer = combineReducers({
  currentUser,
  event,
  loading,
});

export default myReducer;
