// **************** ROUTE CONSTANT START **************************
// General Page Section
export const DASHBOARD = '/dashboard';
export const STATISTICS = '/';
export const STATISTICS_ONGOING = '/ongoing';
export const PRODUCTS = '/products';
export const CATEGORY = '/category';
export const LOGIN = '/login';
export const LOGOUT = '/logout';
export const EVENT = '/events';
export const CUSTOMERS = '/customers';
export const MAKER = '/makers';
export const VOTING_SCREEN = '/voting-screens';
export const EVENT_CONTROLLER = '/events-controller';
export const EVENT_CONTROLLER_REFACTOR = '/events-controller-refactor';
export const STAFF_MEMBERS = '/staff-members';
export const SITE_SETTINGS = '/site-settings';
export const EXPORT_PREVIEW = '/export-preview';
export const EXPORT_PREVIEW_MAKER = '/export-preview-maker';

//stag
// export const GOTOTOP = 'https://questionnaire-client-git-master.xuannghiemnguyen.vercel.app';
// export const API = 'https://18.181.145.27/questionaire-stg-api';

//production
export const GOTOTOP = process.env.REACT_APP_GO_TO_TOP;
export const API = process.env.REACT_APP_API;

// **************** ROUTE CONSTANT END **************************

export const CURRENCY = '$';
