let initialState = {
    onProcessing: false,
    position: 0,
    currentEvent: ''
};

const reducer = (state = initialState, action) => {
    if (action.type === 'SET_PROCESSING') {
        state = { ...state, onProcessing: action.payload };
        return state;
    }
    if (action.type === 'SET_POSITION_CONTROLLER') {
        state = { ...state, position: action.payload };
        return state;
    }
    if (action.type === 'SET_CURRENT_EVENT_ID') {
        state = { ...state, currentEvent: action.payload };
        return state;
    }
    return state;
};

export default reducer;
