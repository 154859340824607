import { useMutation } from '@apollo/react-hooks';
import React from 'react';
import { MUTATION_LOGIN } from '../graphql/login';
import jwt from 'jsonwebtoken';
type AuthProps = {
  isAuthenticated: boolean;
  authenticate: Function;
  signOut: Function;
};
type ResultLogin = any;

export const AuthContext = React.createContext({} as AuthProps);

const isValidToken = () => {
  // JWT decode & check token validity & expiration.
  try {
    const token = localStorage.getItem('access_token');
    if (token) {
      let auth = jwt.decode(token);
      let now = new Date().getTime();
      if (now < auth.expire_time) {
        return { isValid: true, userType: auth.type };
      }
    }
    throw new Error('Unauthorized!');
  } catch (error) {
    localStorage.clear();
    return {
      isValid: false,
      userType: null,
    };
  }
};

const AuthProvider = (props: any) => {
  const [mutation_login] = useMutation(MUTATION_LOGIN);
  const [isAuthenticated, makeAuthenticated] = React.useState(
    isValidToken().isValid,
  );
  function authenticate({ username, password }, cb) {
    //call api login to get questionnaire_token
    mutation_login({
      variables: {
        loginInput: {
          username,
          password,
        },
      },
    })
      .then(({ data }: ResultLogin) => {
        localStorage.setItem('access_token', `${data?.login?.access_token}`);
        localStorage.setItem('username', `${data?.login?.username}`);
        localStorage.setItem('fullName', `${data?.login?.fullName}`);
        makeAuthenticated(true);
        cb(true, 'ログインが成功しました。');
      })
      .catch((error) => {
        cb(false, error);
      });
  }
  function signOut(cb) {
    makeAuthenticated(false);
    localStorage.clear();
    setTimeout(cb, 100);
  }
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        authenticate,
        signOut,
      }}
    >
      <>{props.children}</>
    </AuthContext.Provider>
  );
};

export default AuthProvider;
